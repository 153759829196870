.box {
  background-color: var(--accent-1);
  background-image: url("../../../assets/bg-patt-1.svg");
  background-size: cover;
  padding: 100px clamp(1rem, 4vw, 4rem);
  border-radius: 10px;
  color: #fff;
}
.box p {
  font-size: 0.9rem;
}
.aim {
  display: flex;
  flex-wrap: wrap;
}
.aim > div {
  flex: 230px 1;
  background-color: #fff;
  align-items: center;
  margin: 5px;
  padding: 1rem;
  color: #252525;
  border-radius: 10px;
  display: flex;
  box-shadow: 0 0 10px rgb(0 0 0 / 30%);
  gap: 10px;
}
.aim > div > img {
  max-width: 60px;
}
