.sidebar {
  height: 100%;
}
.sidebar-menu {
  position: absolute;
  width: 300px;
  height: 100%;
  max-width: 300px;
  background-color: #f5f5f5;
  left: -330px;
  top: 0;
  transition: 0.3s ease-in-out;
  padding: 50px 20px;
  z-index: 999999;
  overflow-y: scroll;
}
.open-menu {
  left: 0;
}
.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #333;
  cursor: pointer;
}
.sidebar-menu ul {
  margin: 0;
  padding: 0;
}
.sidebar-menu ul li {
  list-style-type: none;
  margin-bottom: 20px;
}
.sidebar-menu ul a,
.menu-logout {
  color: #333;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.social-icons {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.social-icons i {
  font-size: 24px;
  display: block;
  margin-bottom: 20px;
  color: #fff;
}
.social-icons:before {
  height: 60px;
  top: -80px;
  width: 1px;
  background: #fff;
  content: "";
  position: absolute;
}
@media (min-width: 1100px) {
  .mobile-menu-items {
    display: none;
  }
}
@media (max-width: 768px) {
  .sidebar-menu {
    max-width: 99vw;
    background-color: #fff;
  }
  .open-menu.sidebar-menu {
    left: 0px;
  }
  .close-button {
    font-size: 20px;
    right: 10px;
    top: 10px;
  }
  .sidebar-menu ul li {
    margin-bottom: 10px;
  }
  .sidebar-menu ul a {
    font-size: 15px;
  }
}
