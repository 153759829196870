.modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  position: absolute;
  width: 800px;
  height: 600px;
  max-width: 95%;
  max-height: 95vh;
  overflow: hidden;
  border-radius: 10px;
  word-wrap: break-word;
  background: #fff;
  transform: scale(0);
  animation: 0.3s scaleUp forwards;
}
.content {
  padding: 1rem 2rem;
  max-height: calc(100% - 70px);
  overflow-y: scroll;
}
.cta {
  position: absolute;
  width: 100%;
  height: 70px;
  background: #f4f4f4;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
}
.cta button {
  background: var(--accent-1);
  border: none;
  padding: 8px 18px;
  font-size: 1rem;
  color: #fff;
  border-radius: 25px;
  transition: 0.3s ease;
}
.cta button:hover {
  background-color: #252525;
}
.cta button.close {
  background-color: #252525;
}
.modal_close .modal {
  animation: 0.3s scaleDown;
}
@keyframes scaleUp {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
