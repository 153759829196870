.section {
  margin: 20px;
  max-width: calc(100% - 40px);
  border-radius: 10px;

  overflow: hidden;
}
.content_wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  padding: clamp(1rem, 4vw, 4rem);
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  align-items: flex-end;
}
.content_wrapper:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top, rgb(0 0 0 / 80%) 40%, rgb(0 0 0 / 0%));
  z-index: 1;
}
.hero > div > div {
  height: auto !important;
}
.hero img {
  height: 100%;
  min-height: 80vh;
  object-fit: cover;
}
.content {
  flex: 1;
  color: #fff;
  position: relative;
  z-index: 2;
}
.content h3 {
  font-size: clamp(1.3rem, 2vw, 2rem);
  font-weight: 400;
}
.content h1 {
  font-size: clamp(1.5rem, 3vw, 3.5rem);
}
.content p {
  font-size: clamp(0.9rem, 2vw, 1.2rem);
  margin-bottom: 2rem;
}
.content button {
  background-color: var(--accent-1);
  border: none;
  outline: none;
  padding: 0.8rem 2rem;
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  color: #fff;
  font-weight: 600;
  border-radius: 100px;
  transition: 0.2s ease;
}
.content button:hover {
  color: #010101;
  background-color: #fff;
}
.navigation {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}
.next,
.prev {
  background-color: transparent;
  color: #fff;
  border: none;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  border: 1px solid;
  border-radius: 100px;
  transition: 0.2s ease;
}
.next:hover,
.prev:hover {
  color: var(--accent-1);
  border-color: var(--accent-1);
}

@media (max-width: 768px) {
  .content {
    flex: 100%;
    max-width: 100%;
  }
  .section {
    margin: 10px;
    max-width: calc(100% - 20px);
  }
}
