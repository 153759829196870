footer {
  padding: 50px 0 20px;
  background: #161515;
  color: #fff;
}
footer a {
  text-decoration: underline;
}
.wrapper {
  row-gap: 1rem;
}
.logo {
  max-width: 150px;
}
.logo p {
  font-size: 18px;
  font-weight: 800;
}
.social {
  margin-top: 1rem;
}
.social a {
  margin-right: 15px;
  font-size: 24px;
}
.bottom {
  background-color: #010101;
  color: #fff;
  padding: 10px;
  text-align: center;
}
.bottom a {
  color: var(--accent-1);
  font-weight: 600;
}
.link_head {
  padding-left: 30px;
  position: relative;
}
.link_head:before {
  content: "";
  position: absolute;
  left: 0;
  top: calc(50% - 1px);
  height: 2px;
  width: 25px;
  background: #fff;
}
@media (max-width: 768px) {
  .logo {
    max-width: 200px;
  }
}
