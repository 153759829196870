.intro {
  background-image: url("../../../assets/lamps.jpg");
  background-repeat: no-repeat;
  background-color: #f0edde;
  background-size: auto 100%;
  background-position: left;
  max-width: calc(100% - 40px);
  margin: 0 20px;
  border-radius: 10px;
  padding: 150px 0;
  position: relative;
  overflow: hidden;
}
.intro:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(240 237 222 / 36%);
}
.intro > div {
  position: relative;
  z-index: 2;
}
@media (max-width: 768px) {
  .intro {
    padding: 100px 0;
    max-width: calc(100% - 20px);
    margin: 0 10px;
  }
}
