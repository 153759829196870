.filter_scroll {
  padding: 0 1rem 0 0;
  /* border-right: 1px solid #f4f4f4;
    margin-right: 1rem; */
}
.filters {
  animation: 0.3s pullDown forwards;
}
.filters.close {
  animation: 0.3s pullUp forwards;
}
.filter_scroll h4 {
  margin-bottom: 1rem;
  color: #252525;
  font-size: clamp(0.7rem, 2vw, 1rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.filter_scroll h4 svg {
  font-size: 1rem;
  display: none;
}
.filter:not(:last-child) {
  margin-bottom: clamp(0.2rem, 1vw, 1rem);
}
.filter > * {
  width: 100%;
  display: flex;
  font-size: clamp(0.7rem, 2vw, 1rem);
  align-items: center;
  justify-content: space-between;
  color: #252525;
  cursor: pointer;
}
.filter > *.active {
  color: var(--accent-1);
  font-weight: 600;
}
.filter svg {
  font-size: 0.8rem;
}
@media (max-width: 768px) {
  .filter_scroll h4 svg {
    display: block;
  }
  .filter_scroll {
    padding: 0;
    background-color: #f4f4f4;
    margin-bottom: 1rem;
  }
  .filter_scroll h4 {
    padding: 0.5rem 1rem;
    background-color: rgb(0 0 0 / 5%);
    margin: 0;
  }
  .filter:first-child {
    padding-top: 1rem;
  }
  .filter:last-child {
    padding-bottom: 1rem;
  }
  .filter {
    padding: 0 1rem;
  }
}
@keyframes pullDown {
  0% {
    /* clip-path: inset(0 0 100% 0); */
    max-height: 0;
    opacity: 0;
  }
  100% {
    /* clip-path: inset(0 0 0 0); */
    max-height: 10000px;
    opacity: 1;
  }
}

@keyframes pullUp {
  0% {
    max-height: 10000px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    opacity: 0;
  }
}
