.header {
  padding: 10px 40px;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  position: relative;
  left: 0;
  width: 100%;
  z-index: 9999;
  background: #fff;
}
.header .row {
  align-items: center;
  justify-content: space-between;
}
.logo {
  max-width: 300px;
}
.main-menu {
  text-align: right;
  display: flex;
  align-items: center;
}
.main-menu ul {
  margin: 0;
}
.main-menu li {
  display: inline-block;
  list-style: none;
  margin-right: 30px;
}
.main-menu .menu-icon {
  position: relative;
  top: auto;
  left: auto;
}
.main-menu .menu-icon span {
  background-color: #333;
}
.menu-icon {
  position: absolute;
  top: 25px;
  left: calc(50% - 13px);
  z-index: 999999;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.menu-icon span {
  width: 30px;
  height: 2px;
  margin-bottom: 4px;
  display: block;
  background-color: #fff;
}
.menu-icon span:last-child {
  margin: 0;
  width: 25px;
}
@media (max-width: 1161px) {
  .menu-desktop {
    display: none;
  }
}
@media (max-width: 768px) {
  .header {
    padding: 10px 20px;
  }
  .header .logo {
    max-width: 180px;
  }
}
