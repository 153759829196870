.posts .col-md-4,
.event-page .col-md-4 {
  margin-bottom: 40px;
}
.posts-wrapper {
  margin: 0 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.2s ease;
}
.posts-wrapper:hover .posts-img img {
  transform: scale(1.1);
}

.posts-img {
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}
.posts-img img {
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease;
}
.posts-content {
  padding: 10px 0px;
  margin-bottom: 10px;
  flex: 1;
}
.category-link {
  display: block;
  margin-bottom: 10px;
}
.category {
  width: auto;
  font-size: clamp(0.7rem, 1vw, 0.9rem);
  padding: 1px 8px;
  border-radius: 25px;
  border: 1px solid;
  margin-bottom: 5px;
  align-self: flex-start;
}
.posts-content h2 {
  font-size: clamp(0.9rem, 1.5vw, 1.2rem);
  margin-bottom: 0.6rem;
}
.posts-content .post-desc {
  font-size: 14px;
  color: #838080;
  word-break: break-all;
}
.read-more {
  margin-top: auto;
  background: var(--accent-1);
  padding: 9px 20px;
  border-radius: 25px;
  color: #fff;
  transition: 0.3s ease;
  font-size: clamp(0.8rem, 1.2vw, 1rem);
}
.read-more:hover {
  background-color: #252525;
}

.change-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.change-buttons i {
  font-size: 15px;
}

a.edit-button {
  color: #466aef;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
.delete-button {
  color: #eb3131;
  background-color: transparent;
  border: none;
  outline: none;
  /* width: 30px;
  height: 30px; */
}

.category-list {
  margin-bottom: 50px;
  background: #f4f4f4;
  overflow-x: auto;
  display: flex;
  -webkit-overflow-scrolling: touch;
}

.category-list a {
  padding: 10px 20px;
  border-right: 1px solid rgb(0 0 0 / 5%);
  height: 100%;
  color: #000;
  flex: 0 0 auto;
  transition: 0.2s ease;
}

.category-list a:hover,
.category-list a.active {
  border-bottom: 2px solid #000;
}
.category-list::-webkit-scrollbar {
  display: none;
}
.load-more-wrapper {
  width: 100%;
}
.load-btn {
  text-align: center;
}

button.load-more {
  padding: 10px 50px;
  background: transparent;
  border: none;
  outline: none;
  color: #3c8536;
  text-transform: uppercase;
}

.load-btn p {
  font-size: 20px;
  text-transform: uppercase;
}
.load-btn.hide .load-more {
  display: none;
}
.load-btn {
  margin-top: 100px;
}
