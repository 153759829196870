.post_loader {
  margin: 5px;
}

.image {
  width: 100%;
  aspect-ratio: 4/2;
  background-color: #f4f4f4;
  border-radius: 10px;
  margin-bottom: 5px;
}
.category {
  width: 100%;
  max-width: 100px;
  height: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  margin-bottom: 5px;
}
.title {
  width: 90%;
  height: 24px;
  background-color: #f4f4f4;

  margin-bottom: 10px;
}
.description {
  height: 16px;
  width: 100%;

  background-color: #f4f4f4;
  margin-bottom: 5px;
}
