.admin-panel{
    height: 100vh;
}
.link {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.link a {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
    padding: 20px 40px;
    background: #f7fbf3;
    margin: 5px;
    min-height: 100px;
    border-radius: 5px;
    border: 1px solid #3c8536;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: 0.2s ease;
}

.link span {
    font-size: 17px;
    color: #3c8536;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.link a:hover {
    background: #7ccf76;
}

.link a:hover span {color: #f7fbf3;}
.admin-panel .col-md-6{
    margin-bottom: 40px;
}

@media(max-width: 768px){
    .link span{
        font-size: 12px;
    }
}