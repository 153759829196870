.login{
    height: 100vh;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
}
.form-wrapper{
    padding: 20px 40px;
    background: #fff;
    border: 1px solid #ccc;
    width: 400px;
    max-width: 90%;
    text-align: center;
}
.form-wrapper input:not(.btn){
    display: block;
    width: 100%;
    padding: 10px 20px;
    border: none;
    border-bottom: 1px solid #333;
    margin-bottom: 20px;
}
.form-wrapper .btn{
    margin-bottom: 20px;
}
.error{
    color: red;
}