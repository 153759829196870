.mosque {
  /* padding: 0.5rem; */
  background-color: #f9f9f9;
  border-radius: 10px;
  margin: 0 5px 10px;
  height: calc(100% - 10px);
  display: flex;
  flex-direction: column;
}
.mosque img {
  aspect-ratio: 5/3;
  object-fit: cover;
  display: flex;
  margin-bottom: 1rem;
  border-radius: 10px 10px 0 0;
}
.mosque > div {
  flex: 1;
  padding: 0 1rem 1rem;
  display: flex;
  flex-direction: column;
}
.mosque > div > h4 {
  color: var(--accent-1);
  margin-bottom: 1rem;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
}
.mosque > div > span {
  display: flex;
  gap: 10px;
  margin-bottom: 1rem;
}
.mosque > div > span > svg {
  width: 20px;
  flex: 20px 0 0;
  color: var(--accent-1);
}
.mosque > div > span > span {
  flex: 1 1;
  max-width: calc(100% - 30px);
  line-height: 1.2;
  word-wrap: break-word;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
}

.link {
  text-decoration: underline;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  color: var(--accent-1);
  text-align: right;
  display: block;
  margin-top: auto;
}
.phoneNumber {
  display: block;
  text-decoration: underline;
  color: var(--accent-1);
  margin-bottom: 5px;
}
.mosque_wrapper {
  animation: scaleUp 0.5s;
}
@keyframes scaleUp {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
