.halal-top {
  padding: 100px 0;
  background: url("../../assets/title-bg.png");
  text-align: center;
  background-size: cover;
  background-position: center;
}
.halal-top h1 {
  font-size: 56px;
  color: #fff;
  font-weight: 500;
}

.halal-top .pre-heading,
.halal-top p {
  color: #fff;
}
.halal-top p {
  font-size: 20px;
}
.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.second-para {
  background-color: rgb(250, 248, 248);
  padding-top: 30px;
}
.third-para {
  background-color: white;
}
.conditions-wrap {
  margin-bottom: 20px;
}
.conditions {
  display: inline;
  background-color: rgb(173, 224, 173);
  font-weight: bold;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}
.white {
  color: #fff;
}
.first-div,
.third-div {
  background-color: rgb(250, 248, 248);
  padding-top: 50px;
}
.second-div {
  background-color: rgb(173, 224, 173);
  padding-top: 50px;
}
.cards .col-md-4 {
  max-width: calc(33.33% - 10px);
  margin: 0 5px 10px;
}
.cards h3 {
  margin-bottom: 30px;
}
.halal-title-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: calc(50% - 20px);
  margin: 0 10px;
  border-radius: 5px;
  color: #fff;
  position: relative;
  overflow: hidden;
  padding: 40px;
}
.halal-title-bg::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 60%);
  position: absolute;
  top: 0;
  left: 0;
}
.halal-title-bg.halal-bg-1 {
  background: url("../../assets/halal-bg-1.jpg");
  background-size: cover;
}
.halal-title-bg.halal-bg-2 {
  background: url("../../assets/halal-bg-2.jpg");
  background-size: cover;
}
.halal-title-bg .heading {
  font-size: 50px;
  font-weight: normal;
}
.f-contact {
  border: 1px solid rgb(0 0 0 / 5%);
  width: 100%;
  flex: 1;
  padding: 20px 40px;
  margin: 5px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgb(0 0 0 / 5%);
}
.f-contact img {
  max-width: 100px;
}
h3.org-name {
  margin-bottom: 15px;
}

.f-address address {
  font-style: normal;
  line-height: 1.3;
  margin: 10px 0;
}

.f-des p {
  margin: 0;
  line-height: 1.7;
}

.f-des a {
  color: #3c8536;
}
.f-des h4 {
  margin-bottom: 10px;
}
.halal-page-header {
  padding: 0;
}
.halal-page-header .text-wrapper {
  padding: 40px;
}
.halal-page-header img {
  aspect-ratio: 1;
  object-fit: cover;
  display: block;
}
.haram-section {
  background-image: url("../../assets/food.jpg");
  background-size: cover;
  position: relative;
  background-position: center;
  background-attachment: fixed;
}
.haram-section * {
  z-index: 1;
  color: #fff;
}
.haram-section .pre-heading {
  margin-bottom: 30px;
  font-size: 28px;
}
.haram-section:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 80%);
  z-index: 0;
}
.halal-haram .heading span {
  font-weight: 800;
}
.halal-haram .heading .g {
  color: #4f897e;
}
.halal-haram .heading .b {
  color: #1a98a0;
}
.halal-haram .heading .n {
  color: #0e425c;
}
.halal-haram .heading .o {
  color: #e67343;
}
.halal-haram .heading .r {
  color: #c42c4c;
}
.halal-haram img {
  border-radius: 15px;
  border: 15px solid #f3f3f3;
}
.halal-toyyiban .text-wrapper {
  background: #fff;
  border-radius: 15px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 30px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.halal-toyyiban img {
  max-width: 350px;
}
.ayah img {
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 50%;
}
.halal-product li {
  margin-bottom: 15px;
  list-style: outside;
}
.halal-product ul {
  padding-left: 15px;
}
@media (max-width: 768px) {
  .conditions {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 14px;
  }
  .halal-top {
    padding: 40px 0;
  }
  .halal-title-bg {
    max-width: 100%;
    margin: 10px 0;
  }
  .cards .col-md-4 {
    max-width: 100%;
    flex: 100%;
    margin: 5px 0;
  }
  .halal-top h1 {
    font-size: 30px;
  }
  .mobile-order-2 {
    order: 2;
  }
  .mobile-text-left {
    text-align: left;
  }
  .f-contact {
    padding: 20px 15px;
  }
  .halal-page-header .text-wrapper {
    padding: 20px 10px;
  }
  .haram-section .pre-heading {
    font-size: 18px;
  }
}
