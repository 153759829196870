.card {
  margin: 10px;
}
.card > div {
  overflow: hidden;
  aspect-ratio: 16/9;
  margin-bottom: 0.3rem;
  border-radius: 5px;
}
.card img {
  transform: scale(1.1);
  display: flex;
  height: 100%;
  transition: 0.3s ease;
  object-fit: cover;
}
.card h4 {
  margin-bottom: 0.1rem;
}
.card p {
  font-size: 0.9rem;
}
.card:hover img {
  transform: scale(1);
}
