.quote blockquote{
    font-style: italic;
    margin-bottom: 10px;
}
.quote p{
    font-size: 12px;
}
.quote .arabic{
    font-size: 32px;
    margin-bottom: 10px;
}
.support-logo{
     margin: 5px;
}
.mosque-quote .row{
    margin-bottom: 40px;
}
.mosque-quote img{
    max-width: 50%;
    margin: 0 auto;
}
.map-section p{
    font-size: 18px
}
.map-section img{
    padding: 10px 0 0 10px;
}
.logo-image{
    max-width: 300px;
    margin-bottom: 10px;
}

.super-heading{
    font-size: 42px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.collected-funds i{
    font-size: 48px;
    color: #fdb100;
    margin-bottom: 20px;
}
.col-md-6.collected-funds {
    padding: 40px 20px;
    background: #3c8536;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.collected-funds .target, .money p{
    font-size: 28px;
}
.collected-funds .target span, .currency{
    color: #fdb100;
    font-weight: 800;
    border-bottom: 2px solid;
    font-size: 1.2em;
}
.target-text{
    margin-top: 20px;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
}
.highlighted{
    font-size: 18px;
}
.concept-examples img {
    max-width: 95%;
    margin: 2.5%;
}

.concept-examples .row {
    justify-content: space-between;
}

.concept-examples .text-wrapper {
    background: #bccebc;
    max-width: 95%;
    margin: 2.5%;
    flex: 1;
}

.concept-examples .col-md-6 {
    display: flex;
    width: 100%;
}

.concept-examples li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 15px;
}

.concept-examples h2.pre-heading {
    margin-bottom: 20px;
}
.concept-examples li:before {
    content: '';
    position: absolute;
    left: 0;
    width: 10px;
    height: 1px;
    background: #000;
    top: calc(50% - 0.5px);
}
.hadith{
    margin-top: 50px
}
.hadith p{
    font-size: 18px;
    font-style: italic;
}
.hadith span{
    font-weight: 600;
    color: #3c8536;
}
.testimonial-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 10px;
}

.testimonial-wrapper .icon {
    width: 60px;
    margin-bottom: 20px;
}

.swiper-pagination {
    position: relative;
}
.testimonial-wrapper .text p:last-child {
    font-style: italic;
}
.swiper-slide{
    max-width: 100% !important;
    width: 100% !important;
}
.mySwiper {
    max-width: 90vw;
}

.fundraise .heading{
    color: #3c8536;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .fundraise .pre-heading{
    color: #fdb101;
    font-weight: 700;
  }
  .mosque-quote .quote.text-center h2 {
    font-weight: 700;
    color: #3c8536;
    margin-bottom: 20px;
}
.mosque-quote .quote.text-center {
    padding: 60px 20px;
    margin-bottom: 40px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
}
.our-mission h2.heading {
    color: #3c8536;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
}

.our-mission.cards .col-md-3 {
    background: #fff;
    font-size: 18px;
    padding: 60px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
}

.our-mission.cards .col-md-3:nth-child(2) {
    background: #3c8536;
    color: #fff;
}

.our-mission h2.heading {
    color: #3c8536;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
}

.our-mission.cards .col-md-3 {
    background: #fff;
    font-size: 18px;
    padding: 60px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
}

.our-mission.cards .col-md-3:nth-child(2) {
    background: #3c8536;
    color: #fff;
}

.concept-wrapper-1.row {
    position: relative;
}

h2.concept-text {
    position: absolute;
    width: 250px;
    height: 250px;
    font-size: 34px;
    background: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #3c8536;
    text-transform: uppercase;
}

h2.concept-text:before {
    content: '';
    height: 110%;
    width: 110%;
    position: absolute;
    border: 2px solid #fff;
}
.concept-mobile-heading{
    display: none;
}
@media(max-width: 768px){
    h2.concept-text{
        display: none;
    }
    .concept-mobile-heading{
        display: block;
        font-weight: 700;
        color: #3c8536;
        text-transform: uppercase;
        margin-bottom: 30px;
        text-align: center;
    }
}