.event {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 1rem;
  background-color: #fff;
}
.image_wrapper {
  flex: 25%;
  max-width: 25%;
}
.cta {
  flex: 25%;
  max-width: 25%;
}
.image_wrapper {
  padding: 10px;
}
.cta {
  background-color: rgb(0 0 0 / 5%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-wrap: anywhere;
}
.content > div {
  padding: 1rem;
  width: 100%;
}
.image_wrapper img {
  aspect-ratio: 1/1;
  object-fit: cover;
  display: flex;
  border-radius: 6px;
}
.content h2 {
  font-size: clamp(0.9rem, 1.5vw, 1.2rem);
  margin-bottom: 0.6rem;
}
.content .p {
  font-size: 14px;
  color: #838080;
  word-break: break-all;
}
.cta > span {
  font-size: clamp(0.9rem, 2vw, 1.2rem);
  display: block;
  font-weight: 800;
  color: #252525;
}
.cta > div {
  background: var(--accent-1);
  padding: 9px 20px;
  border-radius: 25px;
  color: #fff;
  transition: 0.3s ease;
  font-size: clamp(0.8rem, 1.2vw, 1rem);
  cursor: pointer;
}
.cta > div:hover {
  background-color: #252525;
}

.event_wrapper {
  flex: 100%;
  max-width: 100%;
}
@media (max-width: 768px) {
  .event_wrapper {
    flex: 50%;
    max-width: 50%;
  }
  .event {
    margin: 0 5px 1rem;
    background-color: #f9f9f9;
    height: calc(100% - 1rem);
  }
  .image_wrapper,
  .cta {
    flex: 100%;
    max-width: 100%;
  }
  .cta {
    margin-top: auto;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media (max-width: 602px) {
  .event_wrapper {
    flex: 100%;
    max-width: 100%;
  }
}
