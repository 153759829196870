@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;700&family=Lora:ital,wght@0,400;0,600;1,400;1,600&family=Poppins:ital,wght@0,400&display=swap");
:root {
  --accent-1: #3c8536;
  --accent-2: #fab101;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* transition: 0.2s linear; */
}
@font-face {
  font-family: "Noto Serif JP";
  src: url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@500;700&display=swap");
  unicode-range: U+3040-309F;
  font-weight: 400;
}
html,
body {
  width: 100%;
  overflow-x: hidden;
  height: auto;
}
.overflow-hidden {
  overflow: hidden;
}
body {
  margin: 0;
  font-family: "Poppins", "Noto Serif JP", serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
  color: inherit;
}
button {
  cursor: pointer;
}
.menu-logout {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.main-menu a {
  text-decoration: none;
  color: #252525;
  position: relative;
  font-weight: 600;
  font-size: 15px;
  transition: 0.3s ease;
}
.mobile-menu-items a {
  color: #fff;
  text-decoration: none;
}
.main-menu a:hover {
  color: var(--accent-2);
}
.main-menu a::before {
  content: "";
  height: 2px;
  width: 100%;
  bottom: -3px;
  left: 0;
  position: absolute;
  /* background: rgb(0 0 0 / 50%); */
  transition: 0.3s ease-in;
}
.main-menu a::after,
.menu-logout:after {
  content: "";
  height: 2px;
  width: 0%;
  bottom: -3px;
  left: 0;
  position: absolute;
  background: var(--accent-1);
  transition: 0.3s ease-in;
}
.menu-logout:hover::after {
  width: 100%;
}
.main-menu a:hover::before,
.menu-logout:hover::before {
  width: 0;
  left: 100%;
}
p {
  margin-bottom: 15px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.align-center {
  align-items: center;
}
.body-data {
  min-height: calc(90vh - 100px);
}
.home {
  width: 100%;
  display: flex;
}
aside {
  flex: 5%;
  max-width: 50px;
  width: 50px;
  background-color: #000;
  height: 100vh;
  align-self: center;
  position: fixed;
  top: 0;
  z-index: 999999;
}
main {
  position: relative;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end;
}
.section-50 {
  padding: 50px 0;
}
section {
  padding: 100px 0;
}
.event-page {
  position: relative;
}
.event-page:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 40%;
  background: #e2ede2;
  height: 100%;
  z-index: -1;
}
.container {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}
.container-fluid {
  max-width: 100%;
}
.col-md-6 {
  max-width: 50%;
  flex: 50%;
}
.col-md-4 {
  max-width: 33.33%;
  flex: 33.33%;
}
.col-md-8 {
  max-width: 66.67%;
  flex: 66.67%;
}
.col-md-10 {
  max-width: 83.33%;
  flex: 83.33%;
}
img {
  width: 100%;
}
.pre-heading {
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.align-center {
  align-items: center;
}
.mt-20 {
  margin-top: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.vd-icon {
  margin: 0 5px;
}
.btn {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  border: 1px solid;
  transition: 0.3s ease;
  padding: 10px 30px;
}
.btn:hover,
.btn.invert {
  background-color: #3c8536;
  border-color: #3c8536;
}
.btn.invert:hover {
  background: transparent;
  color: #3c8536;
}
.btn i {
  margin-right: 10px;
}
.light-grey {
  background: #f4f4f4;
}
.intro .pre-heading {
  margin-bottom: 0;
}
.heading {
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 500;
  position: relative;
}

.cards .col-md-3 {
  background: #f4f4f4;
  padding: 40px 20px;
  text-align: center;
  margin: 0 5px 10px;
}
.home-cards .col-md-3 {
  padding: 20px;
}
.cards .row {
  row-gap: 1rem;
}
.cards img {
  margin: 0 auto 10px;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: 10px;
}
.home-cards.cards img {
  max-width: 100%;
}
.card-heading {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}
ul {
  margin: 0;
  list-style: none;
}
.list-items li {
  margin-bottom: 15px;
  font-size: 16px;
  position: relative;
  padding-left: 15px;
}
.list-items li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background: #3c8536;
}
.card-box {
  padding: 80px 40px;
  background: #bccebc;
  font-size: 18px;
  border-radius: 5px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.no-padding {
  padding: 0;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.content {
  padding: 20px;
}
.col-md-12 {
  max-width: 100%;
  flex: 100%;
}
.col-md-9 {
  max-width: calc(75% - 10px);
  flex: 75%;
}
.col-md-2 {
  flex: 16%;
  max-width: 16%;
}
.col-md-3 {
  max-width: calc(25% - 10px);
  flex: 25%;
}
section.not-found {
  width: 100%;
  height: 100vh;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.justify-center {
  justify-content: center;
}
.col-xs-4 {
  width: calc(33.33% - 10px);
  flex: calc(33.33% - 10px);
  margin: 5px;
}
.insta-image {
  background-size: cover;
  padding-bottom: 100%;
}
.not-found h1 {
  font-size: 48px;
  text-transform: uppercase;
}
.text-wrapper {
  padding: 20px 40px;
}
/* Dawah Page */
.president-section {
  background: #f2f7f2;
}
.president-section .president {
  max-width: 150px;
  margin-bottom: 20px;
}
.president-letter {
  border: 10px solid #94ad94;
  max-width: 80%;
}
@media (max-width: 1100px) {
  .col-md-3 {
    max-width: calc(50% - 10px);
    flex: 50%;
  }
}
.col-d {
  margin: 5px;
  border: 1px solid #beb4b4;
  padding: 10px 20px;
}
.col-d img {
  height: 250px;
  object-fit: cover;
  margin-bottom: 20px;
}
.kimono-image,
.skincare-image {
  max-height: 90vh;
  object-fit: cover;
}
._wPDyp {
  flex-basis: calc(100% / 3 - 10px) !important;
}
.category-page .row {
  align-items: center;
  justify-content: center;
}
.category-page .col-md-4 {
  padding: 40px 20px;
  background-color: #bccebc;
  text-align: center;
}
.category-page input[type="submit"] {
  padding: 5px 10px;
  background: #f00;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}
.category-page input[type="text"] {
  background: transparent;
  border: none;
  font-size: 16px;
  color: #000;
}
.category-page .heading {
  color: #384d38;
}
.category-name {
  padding: 10px;
  background: #a4b8a4;
  margin-bottom: 5px;
}
.bank-card {
  padding: 20px 30px;
  border-radius: 5px;
  border: 1px solid #eee;
  margin: 5px;
  width: 100%;
}

.bank-name {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.bank-name .donation-type {
  display: block;
  padding: 2px 6px;
  background-color: #f00;
  color: #fff;
  margin-bottom: 3px;
  text-transform: uppercase;
  font-size: 11px;
}
.bank-name .don {
  display: block;
  padding: 2px 6px;
  background-color: #94ad94;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
}
.bank-card .bank-d {
  color: #94ad94;
}
.bank-card .det {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}
.account-number {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.bank-card img {
  width: 100px;
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .home-emd {
    height: 300px;
  }
  .fb-page {
    width: 100%;
    max-width: 100% !important;
    text-align: center;
  }
  .facebook-container {
    margin-bottom: 20px;
  }

  .president-letter {
    max-width: 100%;
  }
  .text-wrapper {
    padding: 40px 10px;
  }
  section {
    padding: 50px 0;
  }
  .heading {
    font-size: 22px;
  }
  .pre-heading {
    font-size: 18px;
  }
  p {
    font-size: 14px;
  }
  .container {
    padding: 0 10px;
  }
  .col-md-9,
  .col-md-8,
  .col-md-10 {
    max-width: 100%;
    flex: 100%;
  }
  .col-md-3,
  .col-md-2 {
    max-width: 100%;
    flex: 100%;
  }

  .col-md-6,
  .col-md-4 {
    max-width: 100%;
    flex: 100%;
  }
  aside {
    width: 0;
    left: -50px;
  }
  main {
    flex: 1;
    margin: 0;
  }
  .card-box {
    padding: 40px 20px;
    font-size: 14px;
  }
}
@media (max-width: 768px) and (min-width: 490px) {
  .col-sm-6 {
    max-width: 50%;
    flex: 50%;
  }
}
.zoom img {
  max-width: 200px;
  margin: 0 auto;
}
input[type="submit"] {
  cursor: pointer;
}

.fb-embed {
  position: relative;
  padding: 20px;
}

.fb-embed .fb_iframe_widget {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.fb-embed .fb_iframe_widget span {
  width: 100% !important;
}

.fb-embed iframe {
  width: 100% !important;
}
._2p3a {
  width: 100% !important;
}
.mt-30 {
  margin-top: 30px;
}
.mb-50 {
  margin-bottom: 50px;
}
p:last-child {
  margin-bottom: 0;
}
.accent-color {
  color: #3c8536;
}
.b-font {
  font-size: clamp(0.8em, 6vw, 2.8em);
  margin-bottom: 30px;
  font-weight: 800;
}

.halal-page-header ul {
  margin-top: 20px;
  padding-left: 15px;
}
.halal-page-header li {
  margin-bottom: 15px;
  font-size: 18px;
  list-style: outside;
}
