.box {
  background-color: #f4faf0;
  background-size: cover;
  padding: 50px clamp(1rem, 4vw, 4rem);
  border-radius: 10px;
  /* overflow: hidden; */
  position: relative;
}
.box:before,
.box:after {
  content: "";
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: url("../../../assets/bg-shape-2.svg");
}
.box:before {
  left: -250px;
  bottom: -250px;
}
.box:after {
  right: -250px;
  top: -250px;
}
.video_player {
  position: relative;
  cursor: pointer;
  z-index: 2;
}
.video_player img {
  display: flex;
  border-radius: 10px;
  aspect-ratio: 5/3;
  object-fit: cover;
  object-position: center;
  margin-top: -250px;
}
.play {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  width: 60px;
  height: 60px;
  background-color: #fff;
  padding: 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .video_player img {
    margin-top: -100px;
  }
}
