
.row.next-prev-post {
    height: auto !important;
    justify-content: space-between;
    background: #e5e5e5;
    margin-bottom: 20px;
}

.row.next-prev-post a {
    padding: 10px 20px;
    background: #939393;
    color: #fff;
    position: relative;
    min-width: 145px;
    text-align: center;

}
.row.next-prev-post .next{
    margin-left: auto;
}

.post-detail-content{
    padding: 40px 10px;
}
.post-detail-content h2{
    margin-bottom: 20px;
}
.react-Slidy-arrow{
    opacity: 1 !important;
}
.media-wrapper iframe{
    width: 100% !important;
    max-width: 100vw !important;
    min-width: auto !important;
}
.instagram-post .media-wrapper iframe{
    height: 100% !important;
    width: auto !important;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    aspect-ratio: 3/4;
}
@media screen and (max-width: 850px){
.react-Slidy-arrow {
    display: flex !important;
}
}
@media screen and (min-width: 769px){

    .post-detail .row{
        height: 100vh;
        overflow: hidden;
    }

    .post-media, .media-wrapper, .react-Slidy, .react-Slidy div, .react-Slidy ul, .react-Slidy li{
        height: 100%;
        width: auto;
    }
    .post-media img{
        height: auto !important;
        width: 100%;
        object-fit: cover;
        margin: 0 auto;
    }
    .post-contents{
        height: 100%;
        overflow-y: scroll;
    }
    .post-detail-content{
        padding: 100px 40px;
    }
    .media-wrapper iframe{
        width: 100% !important;
        height: 100vh;
        max-width: 100% !important;
        min-width: auto !important;
    }
}

@media(max-width: 768px){
    .instagram-post .media-wrapper iframe{
        width: 100% !important;
        height: auto !important;
        aspect-ratio: 3/5;
    }
}