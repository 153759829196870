.shahadah-top{
    align-items: center;
    background-image: url('../../assets/coverpic.jpg');
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    align-self: center;
    text-align: center;
    position: relative;
}
.shahadah-top:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0/ 80%);
}
.shahadah-top .container{
    z-index: 2;
}
.shahadah-top .heading{
    font-size: 56px;
    line-height: 1.5;
    font-weight: bolder;
    color: #fff;
    align-items: center;
    display: flex;
    align-self: center;
}
.big-text{
    font-size: 28px;
    margin-left: 20px;
    
}

.vertical-center{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.gray{
    background-color: rgb(250, 248, 248);
}

.bigger-text{
    font-size: 32px;
}
.para{
    font-size: 24px;
}
.big-text1{
    font-size: 3rem;
    line-height: 1.5;
    font-weight: 400;
    padding-bottom: 30px;
    color: gray;
}

.small-text{
    font-size:1.5rem ;
    line-height:1.5 ;
    padding: 30px;

}
.white-div{
    background-color: white;
}
.gray-div{
    background-color:rgb(250, 248, 248) ;
}
.green-color{
    background-color: #b7d3b4; 
}
.white-color{
    background-color: white;
    
}
.big-para{
    font-size: 22px;
}
.meeting .heading{
    margin-bottom: 50px;
}
.meeting .post{
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 5px;
}
.meeting .post-content {
    padding: 20px 20px 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.3s ease;
}
.meeting .col-md-4{
    padding: 10px;
    margin-bottom: 40px;
}
.meeting .post-content h3{
    font-size: 24px;
    margin-bottom: 20px;
}
.meeting h2.heading{
    color: #8b8b8b;
}
@media(max-width: 768px){
    .shahadah-top .heading{
        font-size: 30px;
    }
    .shahadah-section .col-md-6:first-child{
        margin-top: 30px;
        order: 2;
    }
    .big-text{
        font-size: 22px;
        text-align: center;
    }
    .para{
        margin-bottom: 30px;
        text-align: center;
    }
    .big-para{
        font-size: 18px;
    }
}