.create-event, .create-post, .edit-post{
  background-color: #f2f7f2;
}
.create-event h1,.create-post h1{
  color: #384d38;
}
.form label{
  width: 200px;
  font-weight: 600;
  color: #94ad94;
}
.new-category label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  color: #9baf94;
  margin-bottom: 10px;
}
.form-group{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.create-post .form{
  margin-right: 10px;
  padding-right: 20px;
  border-right: 2px solid #9baf94;
}
.form-group input, .form-group textarea, .form-group select, .new-category input {
    padding: 15px 20px;
    border: 2px solid #bccebc;
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    flex: 1;
    margin: 0 5px;
}
select{
  color: #939c93;
}
input::placeholder, textarea::placeholder {
    color: #939c93;
    font-size: 14px;
}

.form .check-box {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }
  
  .check-box input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .check-box-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  button.form-btn {
    padding: 15px 20px;
    border: 2px solid #384d38;
    width: 100%;
    border-radius: 5px;
    background-color: #bccebc;
    color: #384d38;
    cursor: pointer;
    transition: 0.2s ease;
}

button.form-btn:hover {
    background: #384d38;
    color: #fff;
}
  .check-box-slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .check-box-slider {
    background-color: #384d38;
  }
  
  input:focus + .check-box-slider {
    box-shadow: 0 0 1px #384d38;
  }
  
  input:checked + .check-box-slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }
  
  /* Rounded check-box-sliders */
  .check-box-slider {
    border-radius: 50px;
  }
  
  .check-box-slider:before {
    border-radius: 50%;
  }
.event-title-wrapper{
    padding: 100px;
    background: #bccebc;
    margin-bottom: 40px;
    text-align: center;

}
.event-title-wrapper h2{
  color: #384d38;
}
.edit-img-wrapper{
  margin-top: 50px;
  
  padding: 40px 20px;
  background: #bccebc;

}
.edit-img-wrapper .col-md-3{
  padding: 10px;
  position: relative;
}
.edit-img-wrapper form{
  position: absolute;
  top: 10px;
  right: 10px;
}
.edit-img-wrapper i {
  color: #fff;
}

  @media(max-width: 768px){
    .form-group{
      flex-direction: column;
      align-items: flex-start;
    }
    .form-group label, .form-group input, .form-group select{
      margin: 0 0 5px;
    }
    .create-post .form {
      margin: 0 0 10px;
      padding: 0 0 10px;
      border: none;
      border-bottom: 2px solid #9baf94;
  }
  }