.modal_wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgb(0 0 0 / 70%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  width: 800px;
  max-width: 90%;
  border-radius: 10px;
  padding: 2rem;
  background-color: #fff;
  margin: 100px auto;
}
.video {
  position: relative;
  height: 0;
  padding: 0 0 56.25%;
  overflow: hidden;
}
.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
