.banner{
    height: 100vh;
    max-height: 640px;
    width: 100%;
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
    position: relative;
    animation: home-slider 10s infinite;
    animation-timing-function: ease-in-out;
}
@keyframes home-slider {
    0%, 100%{
        background-image: url('../../assets/hero-1.jpg');
    }
    33%{
        background-image: url('../../assets/hero-2.jpg');
    }
    66%{
        background-image: url('../../assets/hero-3.jpg');
    }
    
}
.banner:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 40%);
    width: 100%;
    height: 100%;
}
.banner .container{
    z-index: 2;
}
.banner-text{
    z-index: 2;
    width: 100%;
    color: #fff;
}
.banner-text .pre-heading{
    margin: 0;
}
.banner-text h1{
    font-size: 38px;
    text-transform: uppercase;
}
.banner-text p{
    font-size: 18px;
    margin-bottom: 30px;
}
/* Mission Section */

.mission{
    padding: 0;
    background-color: #bccebc;
}
.points p{
    padding-left: 10px;
    margin-bottom: 15px;
    border-left: 2px solid;
}
.mission-text{
    padding:  40px;
}
.home-posts section{
    padding: 0 0 10px;
}
.home-posts .posts-wrapper{
    padding: 20px 20px 40px;
    transition: 0.2s ease;
    background: #f5fff4;
    border-radius: 5px;
}
.justify-center{
    justify-content: center;
}
.home-posts .category-list,.home-posts .load-btn{
    display: none
}
@media(max-width: 768px){
    .mission-text{
        padding: 20px 10px;
        order: 2;
    }
    .banner{
        max-height: 450px;

    }

}