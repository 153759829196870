.firstPost {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.3s ease;
  margin: 0 10px;
  height: 100%;
  display: block;
}
.image {
  height: 100%;
}
.image img {
  display: flex;
  transform: scale(1.1);
  transition: 0.3s ease;
  height: 100%;
  object-fit: cover;
}
.firstPost:hover img,
.post:hover img {
  transform: scale(1);
}
.content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1rem 2rem;
  background: linear-gradient(0deg, black, transparent);
  color: #fff;
  max-height: 100px;
  transition: all 0.3s ease;
}
.category {
  display: inline-block;
  padding: 8px 18px;
  border-radius: 25px;
  background-color: #fff;
  color: #252525;
  text-transform: lowercase;
  line-height: 1;
  margin-bottom: 0.6rem;
}
.firstPost h2 {
  margin-bottom: 1rem;
}
.button {
  background-color: #fff;
  color: #252525;
  margin-left: auto;
  line-height: 1;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 1.2rem;
}

.reveal {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}
.firstPost:hover .content {
  max-height: 230px;
}
.post {
  display: flex;
  padding: 1rem;
  background: #f4faf0;
  border-radius: 10px;
  flex-wrap: wrap;
  align-items: center;
}
.post:hover .hover_icon {
  left: 0;
}
.post:not(:last-child) {
  margin-bottom: 10px;
}
.post_image {
  flex: 33.33%;
  max-width: 33.33%;
  margin-right: 10px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.hover_icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background: rgb(0 0 0 / 40%);
  color: #fff;
  transition: 0.3s ease;
  padding: 1rem;
}
.post_image img {
  display: flex;
  border-radius: 10px;
  transform: scale(1.1);
  transition: 0.3s ease;
}
.post_category {
  font-size: clamp(0.7rem, 1vw, 0.9rem);
  padding: 1px 8px;
  border-radius: 25px;
  border: 1px solid;
  margin-bottom: 5px;
  align-self: flex-start;
}
.post_content {
  flex: calc(66.67% - 10px);
  max-width: calc(66.67% - 10px);
  display: flex;
  flex-direction: column;
}
.post_content h2 {
  font-size: clamp(0.9rem, 1.5vw, 1.2rem);
  margin-bottom: 0.6rem;
}
.post_content > p {
  margin-bottom: 0;
  font-size: clamp(0.7rem, 1.2vw, 1rem);
}
.link {
  display: flex;
  align-items: center;
  line-height: 1;
  padding-bottom: 2px;
  font-size: 0.9rem;
  border-bottom: 1px solid;
  margin-right: 10px;
  transition: 0.3s ease;
  gap: 5px;
  transition: 0.3s ease;
}
.link svg {
  position: relative;
  right: 0;
  transition: 0.3s ease;
}
.link:hover svg {
  right: -10px;
}
@media (max-width: 768px) {
  .firstPost {
    margin: 0 0 1rem;
    height: calc(100% - 1rem);
  }
  .reveal {
    /* margin-bottom: -60px; */
  }
}
